import React from 'react';
import { graphql } from 'gatsby';

import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import EditSource from '../components/EditSource';
import Layout from '../components/Layout';
import MarkdownText from '../components/MarkdownText';
import NestedListItem from '../components/NestedListItem';

const useStyles = makeStyles(theme => ({
  main: {
    '& .footnotes': {
      color: theme.palette.grey[500],
      '& > hr': {
        display: 'none',
      },
      '& ol > li > p': {
        display: 'inline',
      },
    },
  },
}));

const SimplePage = ({
  data: {
    markdownRemark: {
      htmlAst,
      frontmatter: { title, nav } = {},
      parent: { relativePath },
    },
  },
  path,
}) => {
  const classes = useStyles();

  return (
    <Layout title={title}>
      <Grid container spacing={2}>
        {Boolean(nav) && (
          <Grid item md={3}>
            <NestedListItem root={nav} current={path} />
          </Grid>
        )}

        <Grid item md={nav ? 9 : 12}>
          <MarkdownText hast={htmlAst} className={classes.main} />
          <EditSource label="Modifier cette page" sourcePath={`src/md-pages/${relativePath}`} />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default SimplePage;

export const pageQuery = graphql`
  query($id: String) {
    markdownRemark (id: { eq: $id }) {
      htmlAst
      frontmatter { title nav }
      parent { ... on File { relativePath } }
    }
  }
`;
