import React from 'react';

import { Link } from 'gatsby';
import { List, ListItem, ListItemText } from '@material-ui/core';
import useMarkdownPages from '../hooks/useMarkdownPages';

const NestedListItem = ({ current, page, root = '' }) => {
  const pages = useMarkdownPages();
  const subPages = pages.filter(({ relativeDirectory }) => (relativeDirectory === root));

  const maxHeadingsDepth = page?.childMarkdownRemark?.frontmatter?.showHeadings || 0;

  const subHeadings = maxHeadingsDepth
    ? page?.childMarkdownRemark?.headings.filter(({ depth }) => depth <= maxHeadingsDepth)
    : [];

  const listItemProps = {
    component: 'div',
  };
  const listItemTextProps = {
    style: { opacity: 0.5 },
  };

  if (page?.name && !page?.childMarkdownRemark?.frontmatter?.disabled) {
    listItemProps.button = true;
    listItemProps.component = Link;
    listItemProps.to = `/${root}`;

    listItemTextProps.style = { opacity: 1 };
  }

  if (current === listItemProps.to) {
    listItemProps.selected = true;
  }

  let RootComponent = React.Fragment;
  const rootProps = {};

  if (!page?.name) {
    rootProps.dense = true;
    rootProps.component = 'nav';
    RootComponent = List;
  }

  return (
    <RootComponent {...rootProps}>
      <ListItem {...listItemProps}>
        <ListItemText {...listItemTextProps}>
          {page?.childMarkdownRemark?.frontmatter?.title || page?.name || 'Navigation'}
        </ListItemText>
      </ListItem>

      {Boolean(subPages.length) && (
        <List
          dense
          component="div"
          style={{ paddingLeft: '1rem', paddingTop: 0, paddingBottom: 0 }}
        >
          {subPages.map(subPage => (
            <NestedListItem
              key={subPage.name}
              current={current}
              page={subPage}
              root={root ? `${root}/${subPage.name}` : subPage.name}
            />
          ))}
        </List>
      )}

      {Boolean(subHeadings.length) && (
        <List
          dense
          component="div"
          style={{ paddingLeft: '1rem', paddingTop: 0, paddingBottom: 0, fontSize: 0.8 }}
        >
          {subHeadings.map(heading => (
            <ListItem component={Link} to={`/${root}#${heading.id}`}>
              <ListItemText>
                {heading.value}
              </ListItemText>
            </ListItem>
          ))}
        </List>
      )}
    </RootComponent>
  );
};

export default NestedListItem;
