import { graphql, useStaticQuery } from 'gatsby';

export const useMarkdownPages = () => {
  const { wrapper: { nodes } } = useStaticQuery(graphql`
    {
      wrapper: allFile(
        sort: {
          fields: childMarkdownRemark___frontmatter___order
        }
        filter: {
          internal: { mediaType: { eq: "text/markdown" } },
          sourceInstanceName: { eq: "markdown-pages" }
        }
      ) {
        nodes {
          id
          childMarkdownRemark {
            id
            frontmatter { title disabled showHeadings }
            headings { id value depth }
          }
          name
          relativeDirectory
        }
      }
    }
  `);

  return nodes;
};

export default useMarkdownPages;
